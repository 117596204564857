import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Masonry from './masonry-component';

import CreatePostCta from '../create-post-cta';
import styles from './post-list.scss';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import { connect } from '../../../common/components/runtime-context';

const MASONRY_OPTIONS = {
  transitionDuration: 0,
  fitWidth: false,
  gutter: 32,
};

const buildMasonryOptions = ({ isRTL }) => ({ ...MASONRY_OPTIONS, originLeft: !isRTL });

const PostListMasonry = ({
  isRTL,
  category,
  posts,
  type,
  onLikeClick,
  showCreatePostAction,
  ItemComponent,
  itemConfig,
}) => (
  <Masonry
    className={classNames(styles.masonry, styles[type])}
    options={buildMasonryOptions({ isRTL })}
    data-hook="post-list"
  >
    {showCreatePostAction && (
      <div className={classNames(styles.listItem)} key="create-post">
        <CreatePostCta type={type} categorySlug={category && category.slug} />
      </div>
    )}
    {posts.map(post => (
      <div className={classNames(styles.listItem, 'post-list__post-list-item')} key={post._id} id={post._id}>
        <ItemComponent type={type} post={post} onLikeClick={onLikeClick} itemConfig={itemConfig} />
      </div>
    ))}
  </Masonry>
);

PostListMasonry.propTypes = {
  isRTL: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  type: PropTypes.string.isRequired,
  showCreatePostAction: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  itemConfig: PropTypes.object,
};

const mapRuntimeToProps = state => ({
  isRTL: getIsRTL(state),
});

export default connect(mapRuntimeToProps)(PostListMasonry);
