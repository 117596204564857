import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import {
  HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH,
  IS_BANNER_ENABLED_PATH,
  SECTION_HOMEPAGE,
  IS_BANNER_ENABLED_DEFAULT,
  isLayoutProGallery,
  HEADER_SHOW_DEFAULT,
  getAppConfig,
  createPageUrl,
} from '@wix/communities-blog-client-common';

import { getAppData } from '../../../common/selectors/app-data-selectors';
import { handleNavigation } from '../../../common/services/pagination';
import { getFeedPosts, getFeedPostsByPage } from '../../../common/selectors/post-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getBasicParams, getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { isEditor, isSite } from '../../../common/selectors/view-mode-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { simulateComponentError } from '../../../common/services/simulate-error';
import withAppSettings from '../../../common/hoc/with-app-settings';
import withAuth from '../../../common/hoc/with-auth';
import withDeviceType from '../../../common/hoc/with-device-type';
import withCategoryTextAlignment from '../../hoc/with-category-text-alignment';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import FeaturedPostBanner from '../featured-post-banner';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import Page from '../../../common/components/page';
import PageDescription from '../../components/page-description';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { getHasReportedAppLoad } from '../../../common/store/app-loaded/app-loaded-selectors';
import withExperiment from '../../../common/hoc/with-experiment';
import { EXPERIMENT_MOVE_CREATE_POST_BUTTON } from '@wix/communities-blog-experiments';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';

export class FeedPage extends Component {
  state = { isAnimated: !this.props.isLoaded };

  componentDidMount() {
    simulateComponentError(this.props.basicParams, 'feed-page.container');
  }

  componentDidUpdate({ pageSize, excludeContent }) {
    if (this.props.pageSize !== pageSize || excludeContent !== this.props.excludeContent) {
      this.fetchFeedPosts();
    }
  }

  fetchFeedPosts(page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }
    return this.props.fetchFeedPosts({ page, excludeContent: this.props.excludeContent });
  }

  loadMore = page => this.fetchFeedPosts(page);

  renderPosts() {
    const {
      currentPagePosts,
      posts,
      entityCount,
      page,
      isLoading,
      isLoaded,
      showCreatePostAction,
      showPagination,
      layoutType,
    } = this.props;
    const hasPosts = posts.length;

    if (isLoaded && !hasPosts) {
      return <NoPostsFeed />;
    }

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : posts}
          currentPagePosts={currentPagePosts}
          isLoading={isLoading}
          showCreatePostAction={showCreatePostAction}
          section={SECTION_HOMEPAGE}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : posts}
        currentPagePosts={currentPagePosts}
        isLoading={isLoading}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  renderPagination() {
    const { entityCount, pageSize, page, sectionUrl, isSite } = this.props;
    return (
      <Pagination
        page={page}
        pageSize={pageSize}
        entityCount={entityCount}
        createPageUrl={page => createPageUrl(page, sectionUrl)}
        handleNavigation={handleNavigation('/', isSite)}
      />
    );
  }

  render() {
    const { isLoaded, appData, showHeader, homePageTextAlignment, isBannerEnabled, showPagination } = this.props;

    return (
      <Page noSpacing>
        {showHeader && (
          <PageDescription
            title={appData.label}
            description={appData.description}
            cover={appData.cover}
            coverType={appData.coverType}
            overlayColor={appData.overlayColor}
            backgroundColor={appData.backgroundColor}
            textColor={appData.textColor}
            headerSize={300}
            headerTextAlignment={homePageTextAlignment}
          />
        )}
        {isBannerEnabled && <FeaturedPostBanner />}
        <AnimatedLoader isLoading={!isLoaded} isAnimated={this.state.isAnimated}>
          {this.renderPosts()}
        </AnimatedLoader>
        {showPagination && this.renderPagination()}
        {isLoaded && <AppLoaded />}
      </Page>
    );
  }
}

FeedPage.propTypes = {
  appData: PropTypes.object.isRequired,
  category: PropTypes.object,
  currentPagePosts: PropTypes.array,
  entityCount: PropTypes.number,
  excludeContent: PropTypes.bool,
  homePageTextAlignment: PropTypes.number.isRequired,
  isAppLoaded: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isBannerEnabled: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSite: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  lastPage: PropTypes.number,
  newPostId: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  params: PropTypes.object,
  posts: PropTypes.array,
  showCreatePostAction: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showPagination: PropTypes.bool,
  sectionUrl: PropTypes.string,
  fetchFeedPosts: PropTypes.func,
  layoutType: PropTypes.number.isRequired,
};

FeedPage.defaultProps = {
  showHeader: HEADER_SHOW_DEFAULT,
  isBannerEnabled: IS_BANNER_ENABLED_DEFAULT,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const currentPagePosts = getFeedPostsByPage(state, ownProps.page);
  const { sectionUrl } = getAppConfig(state);
  const showCreatePostAction = ownProps.isMoveCreatePostButtonEnabled
    ? getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee)
    : isSite(state) && ownProps.canSee('create', 'post');

  return {
    appData: getAppData(state),
    posts: getFeedPosts(state),
    currentPagePosts,
    newPostId: state.newPostMessage,
    isLoading: getIsFeedPostsLoading(state),
    isLoaded: getIsFeedPostsLoaded(state),
    showCreatePostAction,
    isAppLoaded: getHasReportedAppLoad(state),
    excludeContent: isExcludePostContentSupported(state),
    isEditor: isEditor(state),
    isSite: isSite(state),
    fetchFeedPosts: actions.fetchFeedPostsPromisified,
    sectionUrl,
    basicParams: getBasicParams(state),
  };
};

export default flowRight(
  withPermissions,
  withPaginationSettings(SECTION_HOMEPAGE),
  withAuth,
  withDeviceType,
  withLayoutProps(),
  withAppSettings({
    showHeader: HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH,
    isBannerEnabled: IS_BANNER_ENABLED_PATH,
  }),
  withCategoryTextAlignment,
  withExperiment({
    isMoveCreatePostButtonEnabled: EXPERIMENT_MOVE_CREATE_POST_BUTTON,
  }),
  connect(mapRuntimeToProps),
)(FeedPage);
